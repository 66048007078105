const ROUTES = {
  "/": {
    "key": "home-page",
    "name": "HomePageB3e3f6a1F9ac4947B5731e1b3d8e6fc9",
    "type": "HTML"
  },
  "/_admin/*": {
    "key": "admin-pages",
    "name": "AdminPages9514ec75E7974282Bacb9192c4fa686c",
    "type": "HTML"
  },
  "/about": {
    "key": "about-us-page",
    "name": "AboutUsPage229a0646017b4c9f8419F4cad952ec0f",
    "type": "HTML"
  },
  "/about/leveling": {
    "key": "about-leveling-page",
    "name": "AboutLevelingPage800d60e08be941068d35Afa9d8ea5961",
    "type": "HTML"
  },
  "/events/public": {
    "key": "public-events-page",
    "name": "PublicEventsPage108db6a277b949f598d81bc24fa24aee",
    "type": "HTML"
  },
  "/join": {
    "key": "join-us-page",
    "name": "JoinUsPage4ab35a82A25145c5Baee7ae79b6cc6f7",
    "type": "REFERENCE"
  },
  "/members/*": {
    "key": "member-pages",
    "name": "MemberPages212a4b332d6d4a1b9c0181d9275b0fb4",
    "type": "HTML"
  },
  "/members/messages/*": {
    "key": "message-pages",
    "name": "MessagePages243cc0522dcc445a9f8212aba8943b04",
    "type": "HTML"
  },
  "/resources": {
    "key": "resources-page",
    "name": "ResourcesPageC4ebb618Cf0c42a59c1226160279b04c",
    "type": "REFERENCE"
  },
  "/signup/*": {
    "key": "signup-pages",
    "name": "SignupPagesC95c0fca59104c24Bad8C58e182668c0",
    "type": "REFERENCE"
  }
};

export default ROUTES;