import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "9514ec75-e797-4282-bacb-9192c4fa686c"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPages9514ec75E7974282Bacb9192c4fa686c(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPages9514ec75E7974282Bacb9192c4fa686c`}>
      {props.children}
    </div>
  );
}

// id: "5e3a99dc-dd46-42a7-a4ef-95f58cff3adb"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefault5e3a99dcDd4642a7A4ef95f58cff3adb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefault5e3a99dcDd4642a7A4ef95f58cff3adb page`}>
      {props["header"] || <MembersAreaHeaderD00db465A1f945148a687524c26dbee8 parentTag="HeaderF4a1358b440e40268c8e654f6f02f7fb" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooter6a0e9cdf0b654d84B20f231b166fa362 parentTag="FooterB2292be517c94c7680c7C773474fa377" {...props} />}
    </div>
  );
}

// id: "229a0646-017b-4c9f-8419-f4cad952ec0f"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage229a0646017b4c9f8419F4cad952ec0f(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage229a0646017b4c9f8419F4cad952ec0f page`}>
      {props["header"] || <SecondaryMarketingHeader9737b7d87e0d4bb98f5d1ee0b2ffaf29 parentTag="HeaderAd24cbbf7d20410491c14bb93cb71e53" title="About Us" {...props} />}
      {props["body"] || <MarketingBody9ff2ab2b3da64ece980d119c6d8512b1 parentTag="Body8061682c26684360991e1dda87be611d" postSlug="about-us-posts" {...props} />}
      {props["footer"] || <DefaultFooter6a0e9cdf0b654d84B20f231b166fa362 parentTag="Footer8582359d1bb64921B6adD439ac6710c3" {...props} />}
    </div>
  );
}

// id: "243cc052-2dcc-445a-9f82-12aba8943b04"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages243cc0522dcc445a9f8212aba8943b04(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages243cc0522dcc445a9f8212aba8943b04`}>
      {props["header"] || <MembersAreaHeaderD00db465A1f945148a687524c26dbee8 parentTag="Header13acd67e633447fc8f00438fcf9d3f25" {...props} />}
      {props.children}
    </div>
  );
}

// id: "38618c62-a16f-4c9e-a3de-516825fb52af"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "8def7465-5bfe-46cb-bb78-cfb30b9687f6"
export const BackgroundImage38618c62A16f4c9eA3de516825fb52af = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "5a908f14-6044-42b9-a4b2-73f53fdc7ee9"
// title: ""
// type: :html
// key: "logo"
// parent_id: "6a0e9cdf-0b65-4d84-b20f-231b166fa362"
export function Logo5a908f14604442b9A4b273f53fdc7ee9(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide-white-with-font.svg" alt="Demo 1 logo" className={`${parentTag || ""} Logo5a908f14604442b9A4b273f53fdc7ee9 logo`} />
  );
}

// id: "a0ea13e1-d2f2-45cc-9264-16e7525af8af"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "8def7465-5bfe-46cb-bb78-cfb30b9687f6"
export function LinkedLogoA0ea13e1D2f245cc926416e7525af8af(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogoA0ea13e1D2f245cc926416e7525af8af", parentTag)} label={<DefaultLogoD127c38b84104ee1Be1c066db89a5ab2 parentTag="Label82ed2435078c4fd7B558Aa59a0425f42" alt="logo" />} {...props} />
  );
}

// id: "da30d0d4-505e-422a-9b40-af5a06915e52"
// title: ""
// type: :text
// key: "title"
// parent_id: "1ee98e6b-340d-4d42-848b-77d7a6b4d2cd"
export const TitleDa30d0d4505e422a9b40Af5a06915e52 = "Default Title";

// id: "f5f54e01-67ab-4819-9192-c3fd1dba7476"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "aee5f965-ec3d-4fd8-9e38-892b72851eb0"
export const NavMenuSlugF5f54e0167ab48199192C3fd1dba7476 = "members-primary-nav";

// id: "02d27a09-cdf4-489b-bd9a-e5c975592023"
// title: ""
// type: :text
// key: "title"
// parent_id: "13c3d016-3a23-4f40-b489-6ebf50844ade"
export const Title02d27a09Cdf4489bBd9aE5c975592023 = "Secondary Page";

// id: "b768cf58-fecd-47e5-89f0-260280b8de61"
// title: ""
// type: :text
// key: "title"
// parent_id: "ad24cbbf-7d20-4104-91c1-4bb93cb71e53"
export const TitleB768cf58Fecd47e589f0260280b8de61 = "About Us";

// id: "d127c38b-8410-4ee1-be1c-066db89a5ab2"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogoD127c38b84104ee1Be1c066db89a5ab2(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg" className={`${parentTag || ""} DefaultLogoD127c38b84104ee1Be1c066db89a5ab2 logo`} alt="logo" />
  );
}

// id: "212a4b33-2d6d-4a1b-9c01-81d9275b0fb4"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPages212a4b332d6d4a1b9c0181d9275b0fb4(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPages212a4b332d6d4a1b9c0181d9275b0fb4`}>
      {props["header"] || <MembersAreaHeaderD00db465A1f945148a687524c26dbee8 parentTag="HeaderD1d7a7d6013d490d8910Ad3614698061" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNavCc62c5c90c98450f8fd7Bca5a952adcd", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter6a0e9cdf0b654d84B20f231b166fa362 parentTag="Footer2cdffce35e3d4dbe8fffBa5b7a89cc4c" {...props} />}
    </div>
  );
}

// id: "9737b7d8-7e0d-4bb9-8f5d-1ee0b2ffaf29"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader9737b7d87e0d4bb98f5d1ee0b2ffaf29(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader9737b7d87e0d4bb98f5d1ee0b2ffaf29 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopEb4cbcdfF06e4fe29cbd7fd0580c60ad parentTag="HeaderTop7b365ce2A1a844b6940f36e58071ffcb" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero1ee98e6b340d4d42848b77d7a6b4d2cd parentTag="Hero1fccd584610745baB8ba79acba5b0f9f" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "0fa0e68f-2178-4ca2-a478-40ab44cef377"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "eb4cbcdf-f06e-4fe2-9cbd-7fd0580c60ad"
export function Navigation0fa0e68f21784ca2A47840ab44cef377(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation0fa0e68f21784ca2A47840ab44cef377", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />
  );
}

// id: "1fccd584-6107-45ba-b8ba-79acba5b0f9f"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "9737b7d8-7e0d-4bb9-8f5d-1ee0b2ffaf29"
export function Hero1fccd584610745baB8ba79acba5b0f9f(props: any) {
  return (
    <SecondaryMarketingHero1ee98e6b340d4d42848b77d7a6b4d2cd parentTag="Hero1fccd584610745baB8ba79acba5b0f9f" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "8061682c-2668-4360-991e-1dda87be611d"
// title: ""
// type: :reference
// key: "body"
// parent_id: "229a0646-017b-4c9f-8419-f4cad952ec0f"
export function Body8061682c26684360991e1dda87be611d(props: any) {
  return (
    <MarketingBody9ff2ab2b3da64ece980d119c6d8512b1 parentTag="Body8061682c26684360991e1dda87be611d" postSlug="about-us-posts" {...props} />
  );
}

// id: "b56176ee-cbe9-44df-a3b0-53ba4a4238c3"
// title: ""
// type: :reference
// key: "header"
// parent_id: "108db6a2-77b9-49f5-98d8-1bc24fa24aee"
export function HeaderB56176eeCbe944dfA3b053ba4a4238c3(props: any) {
  return (
    <SecondaryMarketingHeader9737b7d87e0d4bb98f5d1ee0b2ffaf29 parentTag="HeaderB56176eeCbe944dfA3b053ba4a4238c3" title="Public Events" {...props} />
  );
}

// id: "98720ea7-d846-42c4-97be-fd4112cba502"
// title: ""
// type: :text
// key: "alt"
// parent_id: "d127c38b-8410-4ee1-be1c-066db89a5ab2"
export const Alt98720ea7D84642c497beFd4112cba502 = "logo";

// id: "b26b1d5f-392c-4c97-a8ce-690eddaf869e"
// title: ""
// type: :text
// key: "label"
// parent_id: "5feb4db2-951a-4a9d-8146-2aecd5932658"
export const LabelB26b1d5f392c4c97A8ce690eddaf869e = "Join Us";

// id: "28e4a0e1-dba6-453e-a178-a0b78533d565"
// title: ""
// type: :text
// key: "title"
// parent_id: "86ff3f4a-ebda-4397-9dca-f8d554f2e840"
export const Title28e4a0e1Dba6453eA178A0b78533d565 = "Join Us";

// id: "d976ad3b-e806-438e-9dc6-21d2f9111748"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "8d4a39ca-c019-4dbc-9c78-29261dc00c64"
export const PostSlugD976ad3bE806438e9dc621d2f9111748 = "home-page-posts2";

// id: "5a5439fe-189b-4013-b32e-5e0b906db49d"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeader5a5439fe189b4013B32e5e0b906db49d(props: any) {
  return (
    <ShortHeader8def74655bfe46cbBb78Cfb30b9687f6 parentTag="DefaultHeader5a5439fe189b4013B32e5e0b906db49d" navMenuSlug="marketing-primary-nav" title {...props} />
  );
}

// id: "20a3180d-41f3-4a4c-a1f2-da76a75bcf03"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href20a3180d41f34a4cA1f2Da76a75bcf03 = "/";

// id: "d20f6628-b893-4626-a43d-9a22ad6f93a7"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "6a0e9cdf-0b65-4d84-b20f-231b166fa362"
export function LinksD20f6628B8934626A43d9a22ad6f93a7(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("LinksD20f6628B8934626A43d9a22ad6f93a7", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "13acd67e-6334-47fc-8f00-438fcf9d3f25"
// title: ""
// type: :reference
// key: "header"
// parent_id: "243cc052-2dcc-445a-9f82-12aba8943b04"
export function Header13acd67e633447fc8f00438fcf9d3f25(props: any) {
  return (
    <MembersAreaHeaderD00db465A1f945148a687524c26dbee8 parentTag="Header13acd67e633447fc8f00438fcf9d3f25" {...props} />
  );
}

// id: "5feb4db2-951a-4a9d-8146-2aecd5932658"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "b158c821-2ce8-45a2-b8f5-a2369a306e20"
export function Button5feb4db2951a4a9d81462aecd5932658(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button5feb4db2951a4a9d81462aecd5932658", parentTag)} label="Join Us" href="/join" {...props} />
  );
}

// id: "1598191d-212b-4b6f-b838-df79a1e3e5d5"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "81cecaeb-8661-4164-81d6-b5d00153f0ec"
export function HeaderTop1598191d212b4b6fB838Df79a1e3e5d5(props: any) {
  return (
    <DefaultHeaderTopEb4cbcdfF06e4fe29cbd7fd0580c60ad parentTag="HeaderTop1598191d212b4b6fB838Df79a1e3e5d5" {...props} />
  );
}

// id: "453a5cb2-2c1e-4a2a-b174-fb9e57a4a6b9"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "472300ff-1db4-4e84-af8c-49876c769397"
export const PostSlug453a5cb22c1e4a2aB174Fb9e57a4a6b9 = "home-page-posts2";

// id: "002623e1-9ed0-4544-a4a0-1208ad609ce8"
// title: ""
// type: :reference
// key: "label"
// parent_id: "6179bb09-05ac-444b-8a4d-1112ffaa0ac0"
export function Label002623e19ed04544A4a01208ad609ce8(props: any) {
  return (
    <DefaultLogoD127c38b84104ee1Be1c066db89a5ab2 parentTag="Label002623e19ed04544A4a01208ad609ce8" {...props} />
  );
}

// id: "b58b2f00-7078-470b-9fd3-5050db2f115c"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "0fa0e68f-2178-4ca2-a478-40ab44cef377"
export const NavMenuSlugB58b2f007078470b9fd35050db2f115c = "marketing-primary-nav";

// id: "db4a9cad-29c8-4e70-8c5e-6b6c7b59eb3e"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPageDb4a9cad29c84e708c5e6b6c7b59eb3e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPageDb4a9cad29c84e708c5e6b6c7b59eb3e`}>
      {props["header"] || <SecondaryMarketingHeader9737b7d87e0d4bb98f5d1ee0b2ffaf29 parentTag="Header13c3d0163a234f40B4896ebf50844ade" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooter6a0e9cdf0b654d84B20f231b166fa362 parentTag="Footer500abc0e94ef4b229a5bD00c44e9ac11" {...props} />}
    </div>
  );
}

// id: "ed169447-1496-45f1-ba91-914cdec4114b"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function FooterEd169447149645f1Ba91914cdec4114b(props: any) {
  return (
    <DefaultFooter6a0e9cdf0b654d84B20f231b166fa362 parentTag="FooterEd169447149645f1Ba91914cdec4114b" {...props} />
  );
}

// id: "b3e3f6a1-f9ac-4947-b573-1e1b3d8e6fc9"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePageB3e3f6a1F9ac4947B5731e1b3d8e6fc9(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageB3e3f6a1F9ac4947B5731e1b3d8e6fc9 page`}>
      {props["header"] || <HomeHeader81cecaeb8661416481d6B5d00153f0ec parentTag="Header1caf71d9Cf6d497391e6Ae1ab0383e08" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBackA6ceaebe4ffa41048dbbC05bd9f477a5", parentTag)} {...props} />}
      {props["body"] || <MarketingBody9ff2ab2b3da64ece980d119c6d8512b1 parentTag="Body375a261b44774f23A9900b06de400b5a" {...props} />}
      {props["quote"] || <PageQuote parentTag={buildClassName("Quote1872bdd69c6e46c2865e47856cca5d25", parentTag)} contentSlug="home-page-quote" {...props} />}
      {props["footer"] || <DefaultFooter6a0e9cdf0b654d84B20f231b166fa362 parentTag="Footer0e64c2a55df44a92A8feFc7b137d6530" {...props} />}
    </div>
  );
}

// id: "99a8c043-7d24-4208-a615-64435c28d3c8"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "8def7465-5bfe-46cb-bb78-cfb30b9687f6"
export function Navigation99a8c0437d244208A61564435c28d3c8(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation99a8c0437d244208A61564435c28d3c8", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "8582359d-1bb6-4921-b6ad-d439ac6710c3"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "229a0646-017b-4c9f-8419-f4cad952ec0f"
export function Footer8582359d1bb64921B6adD439ac6710c3(props: any) {
  return (
    <DefaultFooter6a0e9cdf0b654d84B20f231b166fa362 parentTag="Footer8582359d1bb64921B6adD439ac6710c3" {...props} />
  );
}

// id: "fe1a1219-8694-4e51-8a90-2ef91334f33a"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "6a0e9cdf-0b65-4d84-b20f-231b166fa362"
export function CopyrightFe1a121986944e518a902ef91334f33a(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("CopyrightFe1a121986944e518a902ef91334f33a", parentTag)} {...props} />
  );
}

// id: "80492871-f8f9-4027-b45d-4d7c673ebbce"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "108db6a2-77b9-49f5-98d8-1bc24fa24aee"
export function Footer80492871F8f94027B45d4d7c673ebbce(props: any) {
  return (
    <DefaultFooter6a0e9cdf0b654d84B20f231b166fa362 parentTag="Footer80492871F8f94027B45d4d7c673ebbce" {...props} />
  );
}

// id: "1caf71d9-cf6d-4973-91e6-ae1ab0383e08"
// title: ""
// type: :reference
// key: "header"
// parent_id: "b3e3f6a1-f9ac-4947-b573-1e1b3d8e6fc9"
export function Header1caf71d9Cf6d497391e6Ae1ab0383e08(props: any) {
  return (
    <HomeHeader81cecaeb8661416481d6B5d00153f0ec parentTag="Header1caf71d9Cf6d497391e6Ae1ab0383e08" {...props} />
  );
}

// id: "227e3bea-407b-44f2-96e6-d122231cc861"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "1872bdd6-9c6e-46c2-865e-47856cca5d25"
export const ContentSlug227e3bea407b44f296e6D122231cc861 = "home-page-quote";

// id: "b13b3aa0-446f-4a54-9b92-0e9595507863"
// title: ""
// type: :text
// key: "title"
// parent_id: "1fccd584-6107-45ba-b8ba-79acba5b0f9f"
export const TitleB13b3aa0446f4a549b920e9595507863 = "Secondary Marketing Title 2";

// id: "87139795-fd28-4c7c-a2a7-5949da71266a"
// title: ""
// type: :text
// key: "title"
// parent_id: "1020edce-1c32-49a2-b6ff-fcf601685a2a"
export const Title87139795Fd284c7cA2a75949da71266a = "Resources";

// id: "8fb4c7e8-c03c-4ecb-911a-29bfe784fd58"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuote8fb4c7e8C03c4ecb911a29bfe784fd58(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuote8fb4c7e8C03c4ecb911a29bfe784fd58`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "c95c0fca-5910-4c24-bad8-c58e182668c0"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPagesC95c0fca59104c24Bad8C58e182668c0(props: any) {
  return (
    <SecondaryPageDb4a9cad29c84e708c5e6b6c7b59eb3e parentTag="SignupPagesC95c0fca59104c24Bad8C58e182668c0" header={<SecondaryMarketingHeader9737b7d87e0d4bb98f5d1ee0b2ffaf29 parentTag="Header86ff3f4aEbda43979dcaF8d554f2e840" title="Join Us" />} {...props} />
  );
}

// id: "472300ff-1db4-4e84-af8c-49876c769397"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCards472300ff1db44e84Af8c49876c769397(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards472300ff1db44e84Af8c49876c769397", parentTag)} postSlug="home-page-posts2" buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "a6ceaebe-4ffa-4104-8dbb-c05bd9f477a5"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "b3e3f6a1-f9ac-4947-b573-1e1b3d8e6fc9"
export function WelcomeBackA6ceaebe4ffa41048dbbC05bd9f477a5(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBackA6ceaebe4ffa41048dbbC05bd9f477a5", parentTag)} {...props} />
  );
}

// id: "44048847-1e7b-46db-9051-b31c0cf9625d"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "5a5439fe-189b-4013-b32e-5e0b906db49d"
export const NavMenuSlug440488471e7b46db9051B31c0cf9625d = "marketing-primary-nav";

// id: "7b365ce2-a1a8-44b6-940f-36e58071ffcb"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "9737b7d8-7e0d-4bb9-8f5d-1ee0b2ffaf29"
export function HeaderTop7b365ce2A1a844b6940f36e58071ffcb(props: any) {
  return (
    <DefaultHeaderTopEb4cbcdfF06e4fe29cbd7fd0580c60ad parentTag="HeaderTop7b365ce2A1a844b6940f36e58071ffcb" {...props} />
  );
}

// id: "d1d7a7d6-013d-490d-8910-ad3614698061"
// title: ""
// type: :reference
// key: "header"
// parent_id: "212a4b33-2d6d-4a1b-9c01-81d9275b0fb4"
export function HeaderD1d7a7d6013d490d8910Ad3614698061(props: any) {
  return (
    <MembersAreaHeaderD00db465A1f945148a687524c26dbee8 parentTag="HeaderD1d7a7d6013d490d8910Ad3614698061" {...props} />
  );
}

// id: "194e9e58-d410-4980-8bb0-525c6ebb73ac"
// title: ""
// type: :reference
// key: "header"
// parent_id: "800d60e0-8be9-4106-8d35-afa9d8ea5961"
export function Header194e9e58D41049808bb0525c6ebb73ac(props: any) {
  return (
    <SecondaryMarketingHeader9737b7d87e0d4bb98f5d1ee0b2ffaf29 parentTag="Header194e9e58D41049808bb0525c6ebb73ac" title="Leveling" {...props} />
  );
}

// id: "f516f834-5226-4978-801f-45f5de6c0686"
// title: ""
// type: :text
// key: "class"
// parent_id: "6179bb09-05ac-444b-8a4d-1112ffaa0ac0"
export const ClassF516f83452264978801f45f5de6c0686 = "linked-logo";

// id: "2b9e167e-4444-4e32-9bf7-db3dcdbb4838"
// title: ""
// type: :text
// key: "title"
// parent_id: "194e9e58-d410-4980-8bb0-525c6ebb73ac"
export const Title2b9e167e44444e329bf7Db3dcdbb4838 = "Leveling";

// id: "d00db465-a1f9-4514-8a68-7524c26dbee8"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeaderD00db465A1f945148a687524c26dbee8(props: any) {
  return (
    <ShortHeader8def74655bfe46cbBb78Cfb30b9687f6 parentTag="MembersAreaHeaderD00db465A1f945148a687524c26dbee8" header-top={<DefaultHeaderTopEb4cbcdfF06e4fe29cbd7fd0580c60ad parentTag="HeaderTopAee5f965Ec3d4fd89e38892b72851eb0" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "1ee98e6b-340d-4d42-848b-77d7a6b4d2cd"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero1ee98e6b340d4d42848b77d7a6b4d2cd(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero1ee98e6b340d4d42848b77d7a6b4d2cd hero`}>
      <div className="title">
        {props["title"] || TitleDa30d0d4505e422a9b40Af5a06915e52}
      </div>
    </div>
  );
}

// id: "108db6a2-77b9-49f5-98d8-1bc24fa24aee"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPage108db6a277b949f598d81bc24fa24aee(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPage108db6a277b949f598d81bc24fa24aee`}>
      {props["header"] || <SecondaryMarketingHeader9737b7d87e0d4bb98f5d1ee0b2ffaf29 parentTag="HeaderB56176eeCbe944dfA3b053ba4a4238c3" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter6a0e9cdf0b654d84B20f231b166fa362 parentTag="Footer80492871F8f94027B45d4d7c673ebbce" {...props} />}
    </div>
  );
}

// id: "fb041c11-000d-438b-b2ba-755e063dec12"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "81cecaeb-8661-4164-81d6-b5d00153f0ec"
export function HeroFb041c11000d438bB2ba755e063dec12(props: any) {
  return (
    <HomePageHeroB158c8212ce845a2B8f5A2369a306e20 parentTag="HeroFb041c11000d438bB2ba755e063dec12" {...props} />
  );
}

// id: "cc62c5c9-0c98-450f-8fd7-bca5a952adcd"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "212a4b33-2d6d-4a1b-9c01-81d9275b0fb4"
export function SecondaryNavCc62c5c90c98450f8fd7Bca5a952adcd(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNavCc62c5c90c98450f8fd7Bca5a952adcd", parentTag)} {...props} />
  );
}

// id: "a97583aa-62aa-4ed0-a237-0614275a5b3a"
// title: ""
// type: :reference
// key: "header"
// parent_id: "4ab35a82-a251-45c5-baee-7ae79b6cc6f7"
export function HeaderA97583aa62aa4ed0A2370614275a5b3a(props: any) {
  return (
    <SecondaryMarketingHeader9737b7d87e0d4bb98f5d1ee0b2ffaf29 parentTag="HeaderA97583aa62aa4ed0A2370614275a5b3a" title="Join Us" {...props} />
  );
}

// id: "13c3d016-3a23-4f40-b489-6ebf50844ade"
// title: ""
// type: :reference
// key: "header"
// parent_id: "db4a9cad-29c8-4e70-8c5e-6b6c7b59eb3e"
export function Header13c3d0163a234f40B4896ebf50844ade(props: any) {
  return (
    <SecondaryMarketingHeader9737b7d87e0d4bb98f5d1ee0b2ffaf29 parentTag="Header13c3d0163a234f40B4896ebf50844ade" title="Secondary Page" {...props} />
  );
}

// id: "e97d4662-13cf-4933-b35d-74ed2edd573a"
// title: ""
// type: :text
// key: "title"
// parent_id: "9737b7d8-7e0d-4bb9-8f5d-1ee0b2ffaf29"
export const TitleE97d466213cf4933B35d74ed2edd573a = "Secondary Marketing Title 1";

// id: "d257e295-8fe3-4b27-a821-3e2762d5e4a4"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "f4a1358b-440e-4026-8c8e-654f6f02f7fb"
export const NavMenuSlugD257e2958fe34b27A8213e2762d5e4a4 = "members-primary-nav";

// id: "e383fefd-447b-43f4-b40d-bd43e9cff821"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "8061682c-2668-4360-991e-1dda87be611d"
export const PostSlugE383fefd447b43f4B40dBd43e9cff821 = "about-us-posts";

// id: "c4ebb618-cf0c-42a5-9c12-26160279b04c"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPageC4ebb618Cf0c42a59c1226160279b04c(props: any) {
  return (
    <SecondaryPageDb4a9cad29c84e708c5e6b6c7b59eb3e parentTag="ResourcesPageC4ebb618Cf0c42a59c1226160279b04c" header={<SecondaryMarketingHeader9737b7d87e0d4bb98f5d1ee0b2ffaf29 parentTag="Header1020edce1c3249a2B6ffFcf601685a2a" title="Resources" />} {...props} />
  );
}

// id: "8def7465-5bfe-46cb-bb78-cfb30b9687f6"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeader8def74655bfe46cbBb78Cfb30b9687f6(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeader8def74655bfe46cbBb78Cfb30b9687f6 site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogoA0ea13e1D2f245cc926416e7525af8af", parentTag)} label={<DefaultLogoD127c38b84104ee1Be1c066db89a5ab2 parentTag="Label82ed2435078c4fd7B558Aa59a0425f42" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation99a8c0437d244208A61564435c28d3c8", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "6a0e9cdf-0b65-4d84-b20f-231b166fa362"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooter6a0e9cdf0b654d84B20f231b166fa362(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooter6a0e9cdf0b654d84B20f231b166fa362 footer`}>
      {props["links"] || <Navigation parentTag={buildClassName("LinksD20f6628B8934626A43d9a22ad6f93a7", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <Logo5a908f14604442b9A4b273f53fdc7ee9 />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("CopyrightFe1a121986944e518a902ef91334f33a", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("Groupflow9b93e78e975c4b628a6cCfb5cd859955", parentTag)} {...props} />}
    </div>
  );
}

// id: "9b93e78e-975c-4b62-8a6c-cfb5cd859955"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "6a0e9cdf-0b65-4d84-b20f-231b166fa362"
export function Groupflow9b93e78e975c4b628a6cCfb5cd859955(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("Groupflow9b93e78e975c4b628a6cCfb5cd859955", parentTag)} {...props} />
  );
}

// id: "ad24cbbf-7d20-4104-91c1-4bb93cb71e53"
// title: ""
// type: :reference
// key: "header"
// parent_id: "229a0646-017b-4c9f-8419-f4cad952ec0f"
export function HeaderAd24cbbf7d20410491c14bb93cb71e53(props: any) {
  return (
    <SecondaryMarketingHeader9737b7d87e0d4bb98f5d1ee0b2ffaf29 parentTag="HeaderAd24cbbf7d20410491c14bb93cb71e53" title="About Us" {...props} />
  );
}

// id: "f4a1358b-440e-4026-8c8e-654f6f02f7fb"
// title: ""
// type: :reference
// key: "header"
// parent_id: "5e3a99dc-dd46-42a7-a4ef-95f58cff3adb"
export function HeaderF4a1358b440e40268c8e654f6f02f7fb(props: any) {
  return (
    <MembersAreaHeaderD00db465A1f945148a687524c26dbee8 parentTag="HeaderF4a1358b440e40268c8e654f6f02f7fb" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "375a261b-4477-4f23-a990-0b06de400b5a"
// title: ""
// type: :reference
// key: "body"
// parent_id: "b3e3f6a1-f9ac-4947-b573-1e1b3d8e6fc9"
export function Body375a261b44774f23A9900b06de400b5a(props: any) {
  return (
    <MarketingBody9ff2ab2b3da64ece980d119c6d8512b1 parentTag="Body375a261b44774f23A9900b06de400b5a" {...props} />
  );
}

// id: "17e7beed-9af4-4873-8f47-2c38a0ffa08c"
// title: ""
// type: :text
// key: "title"
// parent_id: "5a5439fe-189b-4013-b32e-5e0b906db49d"
export const Title17e7beed9af448738f472c38a0ffa08c = null;

// id: "79ead05b-fa03-4a5b-ba51-406b12e874a7"
// title: ""
// type: :text
// key: "title"
// parent_id: "a97583aa-62aa-4ed0-a237-0614275a5b3a"
export const Title79ead05bFa034a5bBa51406b12e874a7 = "Join Us";

// id: "520cda3f-7d69-43c9-9613-e901a873af82"
// title: ""
// type: :text
// key: "title"
// parent_id: "b56176ee-cbe9-44df-a3b0-53ba4a4238c3"
export const Title520cda3f7d6943c99613E901a873af82 = "Public Events";

// id: "9ff2ab2b-3da6-4ece-980d-119c6d8512b1"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBody9ff2ab2b3da64ece980d119c6d8512b1(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBody9ff2ab2b3da64ece980d119c6d8512b1 page-body`}>
      {props["marketingCards"] || <MarketingCards472300ff1db44e84Af8c49876c769397 parentTag="MarketingCards8d4a39caC0194dbc9c7829261dc00c64" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "81cecaeb-8661-4164-81d6-b5d00153f0ec"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeader81cecaeb8661416481d6B5d00153f0ec(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeader81cecaeb8661416481d6B5d00153f0ec site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopEb4cbcdfF06e4fe29cbd7fd0580c60ad parentTag="HeaderTop1598191d212b4b6fB838Df79a1e3e5d5" {...props} />}
      </div>
      {props["hero"] || <HomePageHeroB158c8212ce845a2B8f5A2369a306e20 parentTag="HeroFb041c11000d438bB2ba755e063dec12" {...props} />}
    </div>
  );
}

// id: "800d60e0-8be9-4106-8d35-afa9d8ea5961"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPage800d60e08be941068d35Afa9d8ea5961(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPage800d60e08be941068d35Afa9d8ea5961`}>
      {props["header"] || <SecondaryMarketingHeader9737b7d87e0d4bb98f5d1ee0b2ffaf29 parentTag="Header194e9e58D41049808bb0525c6ebb73ac" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter6a0e9cdf0b654d84B20f231b166fa362 parentTag="Footer276d7410A2964cbd868fEe50c91555e4" {...props} />}
    </div>
  );
}

// id: "6b460d26-61d9-4141-b80e-d0e6bef11b4a"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "472300ff-1db4-4e84-af8c-49876c769397"
export const ButtonClass6b460d2661d94141B80eD0e6bef11b4a = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "aee5f965-ec3d-4fd8-9e38-892b72851eb0"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "d00db465-a1f9-4514-8a68-7524c26dbee8"
export function HeaderTopAee5f965Ec3d4fd89e38892b72851eb0(props: any) {
  return (
    <DefaultHeaderTopEb4cbcdfF06e4fe29cbd7fd0580c60ad parentTag="HeaderTopAee5f965Ec3d4fd89e38892b72851eb0" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "0e64c2a5-5df4-4a92-a8fe-fc7b137d6530"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "b3e3f6a1-f9ac-4947-b573-1e1b3d8e6fc9"
export function Footer0e64c2a55df44a92A8feFc7b137d6530(props: any) {
  return (
    <DefaultFooter6a0e9cdf0b654d84B20f231b166fa362 parentTag="Footer0e64c2a55df44a92A8feFc7b137d6530" {...props} />
  );
}

// id: "693d3a48-dbd4-42cd-b0fe-74d2cfcd2c60"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "d127c38b-8410-4ee1-be1c-066db89a5ab2"
export const ImageUrl693d3a48Dbd442cdB0fe74d2cfcd2c60 = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg";

// id: "82ed2435-078c-4fd7-b558-aa59a0425f42"
// title: ""
// type: :reference
// key: "label"
// parent_id: "a0ea13e1-d2f2-45cc-9264-16e7525af8af"
export function Label82ed2435078c4fd7B558Aa59a0425f42(props: any) {
  return (
    <DefaultLogoD127c38b84104ee1Be1c066db89a5ab2 parentTag="Label82ed2435078c4fd7B558Aa59a0425f42" {...props} />
  );
}

// id: "31401959-7b9a-488f-8305-7fbe8027d2aa"
// title: ""
// type: :text
// key: "class"
// parent_id: "0fa0e68f-2178-4ca2-a478-40ab44cef377"
export const Class314019597b9a488f83057fbe8027d2aa = "navigation";

// id: "e6f5a367-3b5b-4d37-80a5-387a2bcb19d8"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function LabelE6f5a3673b5b4d3780a5387a2bcb19d8(props: any) {
  return (
    <DefaultLogoD127c38b84104ee1Be1c066db89a5ab2 parentTag="LabelE6f5a3673b5b4d3780a5387a2bcb19d8" {...props} />
  );
}

// id: "eb4cbcdf-f06e-4fe2-9cbd-7fd0580c60ad"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTopEb4cbcdfF06e4fe29cbd7fd0580c60ad(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTopEb4cbcdfF06e4fe29cbd7fd0580c60ad header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo6179bb0905ac444b8a4d1112ffaa0ac0", parentTag)} label={<DefaultLogoD127c38b84104ee1Be1c066db89a5ab2 parentTag="Label002623e19ed04544A4a01208ad609ce8" alt="logo" />} className="linked-logo" url="/" {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation0fa0e68f21784ca2A47840ab44cef377", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />}
    </div>
  );
}

// id: "5210264e-4d73-40d2-aa63-07c933e9a20f"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const Title5210264e4d7340d2Aa6307c933e9a20f = "Leveling";

// id: "8d4a39ca-c019-4dbc-9c78-29261dc00c64"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "9ff2ab2b-3da6-4ece-980d-119c6d8512b1"
export function MarketingCards8d4a39caC0194dbc9c7829261dc00c64(props: any) {
  return (
    <MarketingCards472300ff1db44e84Af8c49876c769397 parentTag="MarketingCards8d4a39caC0194dbc9c7829261dc00c64" postSlug="home-page-posts2" {...props} />
  );
}

// id: "2cdffce3-5e3d-4dbe-8fff-ba5b7a89cc4c"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "212a4b33-2d6d-4a1b-9c01-81d9275b0fb4"
export function Footer2cdffce35e3d4dbe8fffBa5b7a89cc4c(props: any) {
  return (
    <DefaultFooter6a0e9cdf0b654d84B20f231b166fa362 parentTag="Footer2cdffce35e3d4dbe8fffBa5b7a89cc4c" {...props} />
  );
}

// id: "6179bb09-05ac-444b-8a4d-1112ffaa0ac0"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "eb4cbcdf-f06e-4fe2-9cbd-7fd0580c60ad"
export function LinkedLogo6179bb0905ac444b8a4d1112ffaa0ac0(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo6179bb0905ac444b8a4d1112ffaa0ac0", parentTag)} label={<DefaultLogoD127c38b84104ee1Be1c066db89a5ab2 parentTag="Label002623e19ed04544A4a01208ad609ce8" alt="logo" />} className="linked-logo" url="/" {...props} />
  );
}

// id: "86ff3f4a-ebda-4397-9dca-f8d554f2e840"
// title: ""
// type: :reference
// key: "header"
// parent_id: "c95c0fca-5910-4c24-bad8-c58e182668c0"
export function Header86ff3f4aEbda43979dcaF8d554f2e840(props: any) {
  return (
    <SecondaryMarketingHeader9737b7d87e0d4bb98f5d1ee0b2ffaf29 parentTag="Header86ff3f4aEbda43979dcaF8d554f2e840" title="Join Us" {...props} />
  );
}

// id: "1020edce-1c32-49a2-b6ff-fcf601685a2a"
// title: ""
// type: :reference
// key: "header"
// parent_id: "c4ebb618-cf0c-42a5-9c12-26160279b04c"
export function Header1020edce1c3249a2B6ffFcf601685a2a(props: any) {
  return (
    <SecondaryMarketingHeader9737b7d87e0d4bb98f5d1ee0b2ffaf29 parentTag="Header1020edce1c3249a2B6ffFcf601685a2a" title="Resources" {...props} />
  );
}

// id: "512e8d77-8657-46ec-bf49-65fedb876e96"
// title: ""
// type: :text
// key: "href"
// parent_id: "5feb4db2-951a-4a9d-8146-2aecd5932658"
export const Href512e8d77865746ecBf4965fedb876e96 = "/join";

// id: "2424d414-ba00-47b3-9247-a10ccc73bce1"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "d20f6628-b893-4626-a43d-9a22ad6f93a7"
export const NavMenuSlug2424d414Ba0047b39247A10ccc73bce1 = "footer-nav";

// id: "4ab35a82-a251-45c5-baee-7ae79b6cc6f7"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPage4ab35a82A25145c5Baee7ae79b6cc6f7(props: any) {
  return (
    <SecondaryPageDb4a9cad29c84e708c5e6b6c7b59eb3e parentTag="JoinUsPage4ab35a82A25145c5Baee7ae79b6cc6f7" header={<SecondaryMarketingHeader9737b7d87e0d4bb98f5d1ee0b2ffaf29 parentTag="HeaderA97583aa62aa4ed0A2370614275a5b3a" title="Join Us" />} {...props} />
  );
}

// id: "2528c13b-48d5-42f4-b682-004e64a5820c"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function Header2528c13b48d542f4B682004e64a5820c(props: any) {
  return (
    <MembersAreaHeaderD00db465A1f945148a687524c26dbee8 parentTag="Header2528c13b48d542f4B682004e64a5820c" {...props} />
  );
}

// id: "b158c821-2ce8-45a2-b8f5-a2369a306e20"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHeroB158c8212ce845a2B8f5A2369a306e20(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHeroB158c8212ce845a2B8f5A2369a306e20 hero`}>
      <div className="title">
        Our mission is to change the world by promoting healthy intimacy through community and education.
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("Button5feb4db2951a4a9d81462aecd5932658", parentTag)} label="Join Us" href="/join" {...props} />}
      </div>
    </div>
  );
}

// id: "2fb01b44-4958-4ed7-884e-e7582c01134f"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "81cecaeb-8661-4164-81d6-b5d00153f0ec"
export const BackgroundImage2fb01b4449584ed7884eE7582c01134f = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/Moving_Forest_1050_700.webp";

// id: "b2292be5-17c9-4c76-80c7-c773474fa377"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "5e3a99dc-dd46-42a7-a4ef-95f58cff3adb"
export function FooterB2292be517c94c7680c7C773474fa377(props: any) {
  return (
    <DefaultFooter6a0e9cdf0b654d84B20f231b166fa362 parentTag="FooterB2292be517c94c7680c7C773474fa377" {...props} />
  );
}

// id: "1872bdd6-9c6e-46c2-865e-47856cca5d25"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "b3e3f6a1-f9ac-4947-b573-1e1b3d8e6fc9"
export function Quote1872bdd69c6e46c2865e47856cca5d25(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("Quote1872bdd69c6e46c2865e47856cca5d25", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "500abc0e-94ef-4b22-9a5b-d00c44e9ac11"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "db4a9cad-29c8-4e70-8c5e-6b6c7b59eb3e"
export function Footer500abc0e94ef4b229a5bD00c44e9ac11(props: any) {
  return (
    <DefaultFooter6a0e9cdf0b654d84B20f231b166fa362 parentTag="Footer500abc0e94ef4b229a5bD00c44e9ac11" {...props} />
  );
}

// id: "276d7410-a296-4cbd-868f-ee50c91555e4"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "800d60e0-8be9-4106-8d35-afa9d8ea5961"
export function Footer276d7410A2964cbd868fEe50c91555e4(props: any) {
  return (
    <DefaultFooter6a0e9cdf0b654d84B20f231b166fa362 parentTag="Footer276d7410A2964cbd868fEe50c91555e4" {...props} />
  );
}

// id: "715847b7-93d6-4fa0-9fef-cbbc3fb43b9d"
// title: ""
// type: :text
// key: "url"
// parent_id: "6179bb09-05ac-444b-8a4d-1112ffaa0ac0"
export const Url715847b793d64fa09fefCbbc3fb43b9d = "/";

// id: "78f44188-5556-44b0-8817-531348246398"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "99a8c043-7d24-4208-a615-64435c28d3c8"
export const NavMenuSlug78f44188555644b08817531348246398 = "members-primary-nav";

const Components = {
  AdminPages9514ec75E7974282Bacb9192c4fa686c,
  MembersAreaDefault5e3a99dcDd4642a7A4ef95f58cff3adb,
  AboutUsPage229a0646017b4c9f8419F4cad952ec0f,
  MessagePages243cc0522dcc445a9f8212aba8943b04,
  BackgroundImage38618c62A16f4c9eA3de516825fb52af,
  Logo5a908f14604442b9A4b273f53fdc7ee9,
  LinkedLogoA0ea13e1D2f245cc926416e7525af8af,
  TitleDa30d0d4505e422a9b40Af5a06915e52,
  NavMenuSlugF5f54e0167ab48199192C3fd1dba7476,
  Title02d27a09Cdf4489bBd9aE5c975592023,
  TitleB768cf58Fecd47e589f0260280b8de61,
  DefaultLogoD127c38b84104ee1Be1c066db89a5ab2,
  MemberPages212a4b332d6d4a1b9c0181d9275b0fb4,
  SecondaryMarketingHeader9737b7d87e0d4bb98f5d1ee0b2ffaf29,
  Navigation0fa0e68f21784ca2A47840ab44cef377,
  Hero1fccd584610745baB8ba79acba5b0f9f,
  Body8061682c26684360991e1dda87be611d,
  HeaderB56176eeCbe944dfA3b053ba4a4238c3,
  Alt98720ea7D84642c497beFd4112cba502,
  LabelB26b1d5f392c4c97A8ce690eddaf869e,
  Title28e4a0e1Dba6453eA178A0b78533d565,
  PostSlugD976ad3bE806438e9dc621d2f9111748,
  DefaultHeader5a5439fe189b4013B32e5e0b906db49d,
  Href20a3180d41f34a4cA1f2Da76a75bcf03,
  LinksD20f6628B8934626A43d9a22ad6f93a7,
  Header13acd67e633447fc8f00438fcf9d3f25,
  Button5feb4db2951a4a9d81462aecd5932658,
  HeaderTop1598191d212b4b6fB838Df79a1e3e5d5,
  PostSlug453a5cb22c1e4a2aB174Fb9e57a4a6b9,
  Label002623e19ed04544A4a01208ad609ce8,
  NavMenuSlugB58b2f007078470b9fd35050db2f115c,
  SecondaryPageDb4a9cad29c84e708c5e6b6c7b59eb3e,
  FooterEd169447149645f1Ba91914cdec4114b,
  HomePageB3e3f6a1F9ac4947B5731e1b3d8e6fc9,
  Navigation99a8c0437d244208A61564435c28d3c8,
  Footer8582359d1bb64921B6adD439ac6710c3,
  CopyrightFe1a121986944e518a902ef91334f33a,
  Footer80492871F8f94027B45d4d7c673ebbce,
  Header1caf71d9Cf6d497391e6Ae1ab0383e08,
  ContentSlug227e3bea407b44f296e6D122231cc861,
  TitleB13b3aa0446f4a549b920e9595507863,
  Title87139795Fd284c7cA2a75949da71266a,
  PageQuote8fb4c7e8C03c4ecb911a29bfe784fd58,
  SignupPagesC95c0fca59104c24Bad8C58e182668c0,
  MarketingCards472300ff1db44e84Af8c49876c769397,
  WelcomeBackA6ceaebe4ffa41048dbbC05bd9f477a5,
  NavMenuSlug440488471e7b46db9051B31c0cf9625d,
  HeaderTop7b365ce2A1a844b6940f36e58071ffcb,
  HeaderD1d7a7d6013d490d8910Ad3614698061,
  Header194e9e58D41049808bb0525c6ebb73ac,
  ClassF516f83452264978801f45f5de6c0686,
  Title2b9e167e44444e329bf7Db3dcdbb4838,
  MembersAreaHeaderD00db465A1f945148a687524c26dbee8,
  SecondaryMarketingHero1ee98e6b340d4d42848b77d7a6b4d2cd,
  PublicEventsPage108db6a277b949f598d81bc24fa24aee,
  HeroFb041c11000d438bB2ba755e063dec12,
  SecondaryNavCc62c5c90c98450f8fd7Bca5a952adcd,
  HeaderA97583aa62aa4ed0A2370614275a5b3a,
  Header13c3d0163a234f40B4896ebf50844ade,
  TitleE97d466213cf4933B35d74ed2edd573a,
  NavMenuSlugD257e2958fe34b27A8213e2762d5e4a4,
  PostSlugE383fefd447b43f4B40dBd43e9cff821,
  ResourcesPageC4ebb618Cf0c42a59c1226160279b04c,
  ShortHeader8def74655bfe46cbBb78Cfb30b9687f6,
  DefaultFooter6a0e9cdf0b654d84B20f231b166fa362,
  Groupflow9b93e78e975c4b628a6cCfb5cd859955,
  HeaderAd24cbbf7d20410491c14bb93cb71e53,
  HeaderF4a1358b440e40268c8e654f6f02f7fb,
  Body375a261b44774f23A9900b06de400b5a,
  Title17e7beed9af448738f472c38a0ffa08c,
  Title79ead05bFa034a5bBa51406b12e874a7,
  Title520cda3f7d6943c99613E901a873af82,
  MarketingBody9ff2ab2b3da64ece980d119c6d8512b1,
  HomeHeader81cecaeb8661416481d6B5d00153f0ec,
  AboutLevelingPage800d60e08be941068d35Afa9d8ea5961,
  ButtonClass6b460d2661d94141B80eD0e6bef11b4a,
  HeaderTopAee5f965Ec3d4fd89e38892b72851eb0,
  Footer0e64c2a55df44a92A8feFc7b137d6530,
  ImageUrl693d3a48Dbd442cdB0fe74d2cfcd2c60,
  Label82ed2435078c4fd7B558Aa59a0425f42,
  Class314019597b9a488f83057fbe8027d2aa,
  LabelE6f5a3673b5b4d3780a5387a2bcb19d8,
  DefaultHeaderTopEb4cbcdfF06e4fe29cbd7fd0580c60ad,
  Title5210264e4d7340d2Aa6307c933e9a20f,
  MarketingCards8d4a39caC0194dbc9c7829261dc00c64,
  Footer2cdffce35e3d4dbe8fffBa5b7a89cc4c,
  LinkedLogo6179bb0905ac444b8a4d1112ffaa0ac0,
  Header86ff3f4aEbda43979dcaF8d554f2e840,
  Header1020edce1c3249a2B6ffFcf601685a2a,
  Href512e8d77865746ecBf4965fedb876e96,
  NavMenuSlug2424d414Ba0047b39247A10ccc73bce1,
  JoinUsPage4ab35a82A25145c5Baee7ae79b6cc6f7,
  Header2528c13b48d542f4B682004e64a5820c,
  HomePageHeroB158c8212ce845a2B8f5A2369a306e20,
  BackgroundImage2fb01b4449584ed7884eE7582c01134f,
  FooterB2292be517c94c7680c7C773474fa377,
  Quote1872bdd69c6e46c2865e47856cca5d25,
  Footer500abc0e94ef4b229a5bD00c44e9ac11,
  Footer276d7410A2964cbd868fEe50c91555e4,
  Url715847b793d64fa09fefCbbc3fb43b9d,
  NavMenuSlug78f44188555644b08817531348246398
}

export default Components;